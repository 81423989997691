import React from 'react';
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig';

export const publicRoutes = [
  {
    key: 'login',
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(
      () => import('views/auth-views/authentication/login'),
    ),
  },
  {
    key: 'login-1',
    path: `${AUTH_PREFIX_PATH}/login-1`,
    component: React.lazy(
      () => import('views/auth-views/authentication/login-1'),
    ),
  },
  {
    key: 'register-1',
    path: `${AUTH_PREFIX_PATH}/register-1`,
    component: React.lazy(
      () => import('views/auth-views/authentication/register-1'),
    ),
  },
  {
    key: 'forgot-password',
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(
      () => import('views/auth-views/authentication/forgot-password'),
    ),
  },
  {
    key: 'error-page-1',
    path: `${AUTH_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
  },
  {
    key: 'error-page-2',
    path: `${AUTH_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import('views/auth-views/errors/error-page-2')),
  },
];

export const protectedRoutes = [
  {
    key: 'dashboard.levels',
    path: `${APP_PREFIX_PATH}/dashboards/levels`,
    component: React.lazy(() => import('views/app-views/dashboards/levels')),
  },
  {
    key: 'dashboard.currency',
    path: `${APP_PREFIX_PATH}/dashboards/currency`,
    component: React.lazy(() => import('views/app-views/dashboards/currency')),
  },
  {
    key: 'dashboard.status',
    path: `${APP_PREFIX_PATH}/dashboards/status`,
    component: React.lazy(() => import('views/app-views/dashboards/status')),
  },
  {
    key: 'dashboard.bank',
    path: `${APP_PREFIX_PATH}/dashboards/bank`,
    component: React.lazy(() => import('views/app-views/dashboards/bank')),
  },
  {
    key: 'dashboard.bankLimit',
    path: `${APP_PREFIX_PATH}/dashboards/bankLimit`,
    component: React.lazy(() => import('views/app-views/dashboards/bankLimit')),
  },
  {
    key: 'dashboard.costs',
    path: `${APP_PREFIX_PATH}/dashboards/costs`,
    component: React.lazy(
      () => import('views/app-views/dashboards/payoutMethodCosts'),
    ),
  },
  {
    key: 'dashboard.payoutMethod',
    path: `${APP_PREFIX_PATH}/dashboards/payoutMethod`,
    component: React.lazy(
      () => import('views/app-views/dashboards/payoutMethod'),
    ),
  },
  {
    key: 'dashboard.country',
    path: `${APP_PREFIX_PATH}/dashboards/country`,
    component: React.lazy(() => import('views/app-views/dashboards/country')),
  },
  {
    key: 'homePage.home',
    path: `${APP_PREFIX_PATH}/homePage/home`,
    component: React.lazy(() => import('views/app-views/homePage/home')),
  },
  {
    key: 'operations.beneficiary',
    path: `${APP_PREFIX_PATH}/operations/beneficiary`,
    component: React.lazy(
      () => import('views/app-views/operations/beneficiary'),
    ),
  },
  {
    key: 'operations.sender',
    path: `${APP_PREFIX_PATH}/operations/sender`,
    component: React.lazy(() => import('views/app-views/operations/sender')),
  },
  {
    key: 'operations.order',
    path: `${APP_PREFIX_PATH}/operations/order`,
    component: React.lazy(() => import('views/app-views/operations/order')),
  },
  {
    key: 'operations.limits',
    path: `${APP_PREFIX_PATH}/operations/limits`,
    component: React.lazy(
      () => import('views/app-views/operations/operationLimit'),
    ),
  },
  {
    key: 'correspondents.fxrate',
    path: `${APP_PREFIX_PATH}/correspondents/fxrate`,
    component: React.lazy(
      () => import('views/app-views/correspondents/fxrate'),
    ),
  },
  {
    key: 'correspondents.correspondent',
    path: `${APP_PREFIX_PATH}/correspondents/correspondent`,
    component: React.lazy(
      () => import('views/app-views/correspondents/correspondent'),
    ),
  },
  {
    key: 'correspondents.balanceRecharge',
    path: `${APP_PREFIX_PATH}/correspondents/balanceRecharge`,
    component: React.lazy(
      () => import('views/app-views/correspondents/balanceRecharge'),
    ),
  },
  {
    key: 'correspondents.balanceHistory',
    path: `${APP_PREFIX_PATH}/correspondents/balanceHistory`,
    component: React.lazy(
      () => import('views/app-views/correspondents/balanceHistory'),
    ),
  },
  {
    key: 'correspondents.balance',
    path: `${APP_PREFIX_PATH}/correspondents/balance`,
    component: React.lazy(
      () => import('views/app-views/correspondents/balance'),
    ),
  },
  // {
  //   key: 'correspondents.connection',
  //   path: `${APP_PREFIX_PATH}/correspondents/connection`,
  //   component: React.lazy(
  //     () => import('views/app-views/correspondents/connection'),
  //   ),
  // },
];
