import _forOwn from 'lodash/forOwn';
import _camelCase from 'lodash/camelCase';
import _isPlainObject from 'lodash/isPlainObject';

const traverse = (object, transform) => {
  const structure = Array.isArray(object) ? [] : {};

  _forOwn(object, (value, key) => {
    let newValue = value;
    if (_isPlainObject(value) || Array.isArray(value)) {
      newValue = traverse(value, transform);
    }
    structure[transform(key)] = newValue;
  });

  return structure;
};

export const toCamel = (object) => traverse(object, (key) => _camelCase(key));
