import { Button } from 'antd';
import React from 'react';

const SentryFallback = () => {
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <div className="flex flex-col justify-between h-screen p-6 bg-gray-100">
      <div className="flex justify-center mb-6"></div>
      <div className="flex flex-col items-center justify-center flex-grow text-center">
        <img
          style={{ width: '200px', marginBottom: '20px', marginTop: '100px' }}
          src={`/img/logo.png`}
          alt="Logo"
        />
        <h1 className="text-5xl font-bold mb-4 text-gray-800">Algo fue mal</h1>
        <p className="text-lg mb-6 text-gray-600">
          Por favor intente nuevamente más tarde.
        </p>
        <img
          className="mt-4 w-2/3 max-w-md"
          style={{ width: '500px' }}
          src="/img/others/img-20.png"
          alt="Error illustration"
        />
        <div className="flex justify-center w-full">
          <Button
            type="primary"
            size="large"
            style={{ marginTop: '50px' }}
            onClick={handleReload}
          >
            Reintentar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SentryFallback;
