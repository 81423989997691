import fetch from 'auth/FetchInterceptor';

const AuthService = {};
const LOGIN_URL = '/auth/jwt/login';
const LOGOUT_URL = '/auth/jwt/logout';

AuthService.login = function (data) {
  const urlencoded = new URLSearchParams();
  urlencoded.append('username', data.email);
  urlencoded.append('password', data.password);
  return fetch({
    url: LOGIN_URL,
    method: 'post',
    data: urlencoded,
  });
};

AuthService.logOut = function (data) {
  return fetch({
    url: LOGOUT_URL,
    method: 'post',
  });
};

AuthService.register = function (data) {
  return fetch({
    url: '/auth/register',
    method: 'post',
    data: data,
  });
};

export default AuthService;
