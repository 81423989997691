import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { signOutSuccess } from 'store/slices/authSlice';
import store from '../store';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import { notification } from 'antd';
import { toCamel } from 'utils/toCamel';

const convertResponseToCamelCase = (response) => {
  if (
    response.data &&
    response.headers['content-type'] &&
    response.headers['content-type'].startsWith('application/json')
  ) {
    response.data = toCamel(response.data);
  }
  return response.data;
};

const unauthorizedCode = [401, 403];
let isAuthErrorHandled = false;

const service = axios.create({
  baseURL: API_BASE_URL + '/api/v1/',
  timeout: 60000,
});

// Config
const TOKEN_PAYLOAD_KEY = 'Authorization';

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
    const token = `Bearer ${jwtToken}`;
    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = token;
    }

    return config;
  },
  (error) => {
    notification.error({
      message: 'Service Error',
    });
    return Promise.reject(error);
  },
);

// API Response interceptor
service.interceptors.response.use(
  (response) => {
    isAuthErrorHandled = false; // Reset the flag on a successful response
    return convertResponseToCamelCase(response);
  },
  (error) => {
    let notificationParam = {
      message: 'Service error',
    };

    if (!error.response) {
      notification.error(notificationParam);
    }

    // Handle authentication errors only once
    if (unauthorizedCode.includes(error?.response?.status)) {
      if (!isAuthErrorHandled) {
        isAuthErrorHandled = true;
        notificationParam.message = 'Falla de autenticación';
        notificationParam.description = 'Por favor ingrese nuevamente';
        localStorage.removeItem(AUTH_TOKEN);
        store.dispatch(signOutSuccess());
      } else {
        return Promise.reject(error);
      }
    }

    if (error.response.status === 400) {
      if (error.response.data && error.response.data.detail) {
        notificationParam.message = error.response.data.detail;
      }
    }

    if (error?.response?.status === 404) {
      notificationParam.message = 'Not Found';
    }

    if (error?.response?.status === 405) {
      notificationParam.message = 'Method Not Allowed';
    }

    if (error?.response?.status === 500) {
      notificationParam.message = 'Internal Server Error';
    }

    if (error?.response?.status === 508) {
      notificationParam.message = 'Time Out';
    }

    notification.error(notificationParam);

    return Promise.reject(error);
  },
);

export default service;
