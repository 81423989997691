/* eslint-disable */

import antdEsES from 'antd/es/locale/es_ES';
import es from './locales/es_ES.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { THEME_CONFIG } from 'configs/AppConfig';

export const resources = {
  es: {
    translation: es,
    antd: antdEsES,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: THEME_CONFIG.locale,
  lng: THEME_CONFIG.locale,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
